._1yzDOa_app {
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  min-height: 100vh;
  padding-top: 40px;
  display: flex;
}

.lFfrAq_typography {
  line-height: 1;
}

.lFfrAq_h1 {
  margin: 0;
  font-size: 3em;
  font-weight: normal;
}

.lFfrAq_h2 {
  margin: 0;
  font-family: Roboto Slab, serif;
  font-weight: lighter;
}

a {
  text-decoration: none;
}

a:hover, a:focus {
  text-decoration: underline;
}

a, a:active {
  color: #fff;
}

.G-7ItW_container {
  flex-direction: column;
  display: flex;
}

.G-7ItW_container.G-7ItW_preventEvents {
  pointer-events: none;
}

.G-7ItW_window {
  z-index: 9999;
  background-color: #1b1d23;
  border-radius: 6px;
  flex-direction: column;
  flex: 1;
  display: flex;
  box-shadow: 0 0 20px #0000004d;
}

.G-7ItW_content {
  flex-direction: column;
  flex: 1;
  margin: 15px;
  display: flex;
  position: relative;
}

.UAshCW_menu {
  box-sizing: border-box;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  background: -moz-linear-gradient(top, #ebebeb, #d5d5d5);
  background: -ms-linear-gradient(top, #ebebeb, #d5d5d5);
  background: -o-linear-gradient(top, #ebebeb, #d5d5d5);
  background: linear-gradient(top, #ebebeb, #d5d5d5);
  background: -moz-linear-gradient(top, #ebebeb, #d5d5d5);
  background: -o-linear-gradient(top, #ebebeb, #d5d5d5);
  background: -webkit-linear-gradient(top, #ebebeb, #d5d5d5);
  background: -moz-linear-gradient(top, #ebebeb, #d5d5d5);
  gap: 4px;
  height: 25px;
  padding: 0 6px;
  display: flex;
}

.hrwjdW_button {
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid #000;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  display: inline-block;
  position: relative;
  top: 6px;
  overflow: hidden;
}

.hrwjdW_button:before {
  content: "";
  pointer-events: none;
  opacity: 0;
  background: #fff;
  transition: opacity .5s cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  inset: 0;
}

.hrwjdW_button:hover:before {
  opacity: .5;
}

.hrwjdW_red {
  background-color: #ff5c5c;
  border-color: #e33e41;
}

.hrwjdW_yellow {
  background-color: #ffbd4c;
  border-color: #e09e3e;
}

.hrwjdW_green {
  background-color: #00ca56;
  border-color: #14ae46;
}

.yxaopa_container {
  min-width: 600px;
  max-width: calc(100% - 40px);
  min-height: 320px;
  max-height: 100vh;
  position: relative;
  overflow-y: auto;
}

.yxaopa_container .yxaopa_resizer {
  z-index: 10000;
  opacity: 0;
  min-width: 10px;
  min-height: 10px;
  position: absolute;
}

.yxaopa_container .yxaopa_resizer.yxaopa_corner {
  background: -moz-linear-gradient(top left, #0000, #fff3);
  background: -ms-linear-gradient(top left, transparent, #fff3);
  background: -o-linear-gradient(top left, #0000, #fff3);
  background: linear-gradient(top left, transparent, #fff3);
  z-index: 10001;
  cursor: se-resize;
  background: -moz-linear-gradient(top left, #0000, #fff3);
  background: -o-linear-gradient(top left, #0000, #fff3);
  background: -webkit-linear-gradient(top left, #0000, #fff3);
  background: -moz-linear-gradient(top left, #0000, #fff3);
  border-bottom-right-radius: 6px;
  bottom: 0;
  right: 0;
}

.yxaopa_container .yxaopa_resizer.yxaopa_right {
  background: -moz-linear-gradient(left, #0000, #fff3);
  background: -ms-linear-gradient(left, transparent, #fff3);
  background: -o-linear-gradient(left, #0000, #fff3);
  background: linear-gradient(left, transparent, #fff3);
  cursor: e-resize;
  background: -moz-linear-gradient(left, #0000, #fff3);
  background: -o-linear-gradient(left, #0000, #fff3);
  background: -webkit-linear-gradient(left, #0000, #fff3);
  background: -moz-linear-gradient(left, #0000, #fff3);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  top: 0;
  bottom: 0;
  right: 0;
}

.yxaopa_container .yxaopa_resizer.yxaopa_bottom {
  background: -moz-linear-gradient(top, #0000, #fff3);
  background: -ms-linear-gradient(top, transparent, #fff3);
  background: -o-linear-gradient(top, #0000, #fff3);
  background: linear-gradient(top, transparent, #fff3);
  cursor: s-resize;
  background: -moz-linear-gradient(top, #0000, #fff3);
  background: -o-linear-gradient(top, #0000, #fff3);
  background: -webkit-linear-gradient(top, #0000, #fff3);
  background: -moz-linear-gradient(top, #0000, #fff3);
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  bottom: 0;
  left: 0;
  right: 0;
}

.yxaopa_container[data-debug] .yxaopa_resizer {
  transition: opacity .15s ease-in-out;
}

.yxaopa_container[data-debug] .yxaopa_resizer:hover {
  opacity: 1;
}

.DPrABq_container {
  margin-left: 20px;
  font-family: Roboto Mono, monospace;
  font-size: 16px;
  line-height: 1.5;
  position: relative;
}

.DPrABq_container .DPrABq_prism {
  pointer-events: none;
  position: absolute;
  inset: 0;
}

.DPrABq_container:before {
  content: "$";
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  top: 0;
  left: -20px;
}

.DPrABq_container .DPrABq_input {
  color: #0000;
  caret-color: #fff;
  font: inherit;
  resize: none;
  background: none;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 0;
}

.QMdkcG_button {
  position: relative;
}

.QMdkcG_button .QMdkcG_hover {
  z-index: 10000;
  opacity: 0;
  background: #fff;
  border-radius: 6px;
  transition: opacity .15s ease-in-out;
  position: absolute;
  inset: 0;
}

.QMdkcG_button:hover .QMdkcG_hover {
  opacity: .25;
}
/*# sourceMappingURL=index.7a1f9187.css.map */
